nav{
    position: fixed;
    display: flex !important;
    flex-flow: row;
    align-items: center;
    padding: 0px 20px !important;
    a{
        margin-right: 20px;
    }
    .logo{
        max-width: 165px;
        object-fit: contain;
        display: block;
    }
    .nav-item{
        h4, p{
            line-height: 1;
        }
    }
}
.sidebar{
    position: fixed;
    z-index: 19;
    max-width: 80px;
    overflow: hidden;
    //transform: translateX(-70%);
    transition: all 0.5s cubic-bezier(0.19, 1, 0.22, 1);
    &.open{
        //transform: translateX(0);
        max-width: 300px;
    }
    li{
        list-style: none;
        padding: 10px;
        margin-bottom: 25px;
        margin-top: 20px;
        font-size: 18px;
        vertical-align: middle;
        
        i{
            vertical-align: middle;
            font-size: 1.7em;
        }
        
        a:hover{
            text-decoration: none; 
        }

    }
    li.lil_menu {
        margin-bottom: 5px;
        margin-top: 5px;
    }
    li:hover {
        background: #333;
        transition: .3s;
        border-radius: 5px;
    }
    li.active {
        background: #ff7534;
    }
}

.menu-options {
    margin-top: 90px;
}

a.text-white:hover, a.text-white:focus {
    color: #d9d9d9 !important;
    text-decoration: none;
}

.hamburguer span{
    width: 31px;
    height: 3px;
    background-color: white;
    margin: 4px 0;
    display: block;
}

.hamburguer:hover {
    border: .5px solid rgb(255, 255, 255);
    border-radius: 10%;
    
}

.user_icon{
    border: white 2px solid;
    border-radius: 50%;
}

.user-profile {
    cursor: pointer;
}
.user-menu {
    float: right;
    min-width: 300px;
    min-height: 120px;
    right: 0px;
    top: 20px;
    position: fixed;
    overflow: hidden;
    transform: translate(0px, -180px);
}

.navbar {
    z-index:10;
}



.user-name:hover {
    cursor: pointer;
}

.user-menu.false {
   /*  height: 0px;
    transition: .1s; */
    animation: close_user_menu .2s normal forwards ease-in-out;
}

.user-menu.true {
    /* height: 200px;
    transition: 1s; */
    animation: open_user_menu .2s normal forwards ease-in-out;
}

@keyframes open_user_menu {
    0%   {transform: translate(0px,-180px);}
    100% {transform: translate(0px, 40px);}
}

@keyframes close_user_menu {
    0%   {transform: translate(0px, 0px);}
    100% {transform: translate(0px, -180px);}
}

@media screen and (max-width: 1440px) {
    .sidebar{
        max-width: 0px;
    }
}

@media screen and (max-width: 600px) {
    
        .user-name {
            display: none;
        }
        .user-description {
            display: none;
        }
        .user_icon {
            font-size: 1.7em;
        }
}

.badge_counter {
    float: right;
    position: absolute;
    right: 8px;
    top: 190px;
    border-radius: 50% !important;
    padding-top: 8px !important;
    padding-bottom: 8px !important;
    width: 31px;
    height: 31px;
}