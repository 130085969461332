.search_input {
    margin: 0 !important;
    width: 40px !important;
    color: #000 !important;
    padding: 0 !important;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    background-color: #fff !important;
    border-left: 0 !important;
    
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    border-color: #ced4da !important;
}

.search_input:focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0) !important;
    background-color: rgb(196, 196, 196) !important;
}

.search_input:hover {
    background-color: rgb(226, 226, 226) !important;
}

.directorios_decarga_buttom {
    padding: 7px 9px 7px 2px !important;
    color: #fff !important;
}

.react-bootstrap-table table { table-layout: auto !important; }

.mapa_container {
    min-width: 200px;
    min-height: 500px;
    padding-bottom: 60px;
}

.pinContainer {
    width: 20px;
    max-height: 1px;
}

.pin {
    width: 30px;
    height: auto;
    position: relative;
    top: -43px;
    left: -12px 
}

.fontw{
    font-weight: 600;
}