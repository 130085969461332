.select-container {
  background-color: white;
  width: 100%;
  color: black;
  border: 1px solid;
  border-color: #D3D3D3;
  text-align: center;
  -webkit-appearance: none;
  -moz-appearance: none;
  position: relative;

  p {
    margin: 0;
    font-family: "Emigre";
    color: #6D6D6D;
    text-transform: uppercase;
  }

  .icon-select {
    background-color: transparent;
    border: none;
    display: inline;
    position: absolute;
    right: 5px;
    top: 2.5px;
    border-left: 1px solid #dadee2;
    color:#F3723C;
  }
}

.options {
  position: absolute;
  background-color: white;
  color: black;
  z-index: 2;
  width: 100.7%;
    margin: -1px;
    margin-left: -7px;
  
  display: flex;
  flex-direction: column;
  font-family: "Emigre";
  border: 1px solid;
  border-color: #D3D3D3;

  .button-option {
    background-color: white;
    border: none;
    padding: 5px;
    font-size: 16px;
    text-transform: uppercase;
  }

  .button-option-active{
    color: red;
  }

  .button-option:hover {
    background-color: #f3723c;
    color: white;
  }
}

.modalchange-title{
  font-family: "Emigre bold";
  text-transform: uppercase;
}


