.modalChange {
  width: 75%;
  margin-left: 24.94%;
  background-color: #4f4f4f;
  border: none;
  border-left: 1px solid #dee2e6;
  text-align: center;
  color: white;
  font-family: "Emigre Bold";
}

.title-modalChange {
  font-family: "Helvetica Neue";
  margin-bottom: 15px;
}

.modalChange-body {
  margin-top: 15px;
}

.modal-accept{
 
  .modal-content {
    border-radius: 20px;
    width: 40%;
    height: 221px;
    margin: 0 auto;
    margin-top: 40%;
  }

  .title-modalChange {
    font-family: "Helvetica Neue";
    font-size: 15px;
    text-align: center;
    margin-top: 40px;

  }

  .modal-close-button {
    background-color: transparent;
    position: absolute;
    right: 5px;
    top: 10px;
    border: none;
  }

  .containerModal-btns {
    display: flex;
    align-items: flex-end;
    height: 20%;
    justify-content: center;
    position: relative;
  
    .btn-modal {
      margin: 8px;
      margin-bottom: 0px;
      font-weight: bold;
      font-family: "Emigre Bold";
      width: 165px;
    }

    .btn-cancel {
      background-color: black;
      color: white;
    }

    .btn-cancel:hover {
      background-color: black;
      color: white;
    }

    .btn-accept {
      background-color: #f3723c;
      color: white;
    }

    .btn-accept:hover {
      background-color: #f3723c;
      color: white;
    }
  }
}

.modal-change {
  .modal-text {
    width: 70%;
    margin: 0 auto;
    margin-top: 30px;
    margin-bottom: 0;
  }
  .modal-content {
    border-radius: 20px;
    width: 100%;
    height: 421px;
    margin: 0 auto;
    margin-top: 30%;
  }

  .modal-close-button {
    background-color: transparent;
    position: absolute;
    right: 5px;
    top: 10px;
    border: none;
  }
  .modal-body-accept {
    text-align: center;

    .btn-modal {
      margin: 8px;
      margin-bottom: 0px;
      font-weight: bold;
      font-family: "Emigre Bold";
      width: 165px;
    }

    .btn-cancel {
      background-color: black;
      color: white;
    }

    .btn-cancel:hover {
      background-color: black;
      color: white;
    }

    .btn-accept {
      background-color: #f3723c;
      color: white;
    }

    .btn-accept:hover {
      background-color: #f3723c;
      color: white;
    }
  }
}

.modalChange:hover{
  background-color:#f3723c ;
}

.modal .modal-body {
  margin: 0px 0 5px !important;
}

.containerModal-btns {
  display: flex;
  align-items: flex-end;
  height: 70%;
  justify-content: center;
  position: relative;

  .warning{
    position: absolute;
    bottom: 26px;
    left: 262px;
    font-family: 'Emigre';
    
    color: red;
  }
}



@media (min-width: 576px) {
  .modal-dialog {
    max-width: 900px !important;
    margin: 1.75rem auto;
  }
}

@media (max-width: 767px) {
  .modalChange {
    width: 100%;
    margin: 0;
    border: none;
  }
}
