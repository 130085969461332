body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.bg-danger {
  padding: 4px 10px;
}


/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 6px;
	background-color: #F5F5F5;
}

/* Track */
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 0px;
	background-color: #F5F5F5;
}

/* Handle */
::-webkit-scrollbar-thumb {
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: rgb(124, 124, 124);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.Toastify__toast--warning {
  background: #ff7534 !important;
}

.custom-checkbox .form-check-input:checked~.custom-control-label::before{
  background-color:#000;
}
  
