$orangeLC: #ff7534;

$theme-colors: (
  "primary": $orangeLC,
  "secondary": #BCBFCF,
  "dark": #424242,
  "white": #fff,
  "grayLC": #CCC9CA,
  "textGrayLC": #807E7F,
);

  $tableProductBadge: #ab2d6c;
  $tableServiceBadge: #50923f;
  $tableTimeBadge: #44b9c2;
  $tableTicketBadge:#FF9A62;
  $tableOtherBadge: #7853bb;
  $tableProblemOredringBadge: #d43aad;
  $tableProblemPickingUpOrderBadge: #7a0b24;

.table-badge-box {
  width: 10px;
  height: 10px;
  display: inline-block;
  margin-right: 5px;
}

.badge-box {
  &__product {
    @extend .table-badge-box;
    background-color: $tableProductBadge;
    &-presentacion {
      @extend .table-badge-box;
      background-color: #f30c0c ;
    }
    &-sabor {
      @extend .table-badge-box;
      background-color: #f30c70;
    }
    &-temperatura {
      @extend .table-badge-box;
      background-color: #f30cb9;
    }
    &-orden-incorrecta {
      @extend .table-badge-box;
      background-color: #e70cf3;
    }
  }
  &__service {
    @extend .table-badge-box;
    background-color: $tableServiceBadge;
    &-orden-incorrecta {
      @extend .table-badge-box;
      background-color: #a60cf3;
    }
    &-sucursal-sucia {
      @extend .table-badge-box;
      background-color: #740cf3;
    }
    &-atencion {
      @extend .table-badge-box;
      background-color: #170cf3;
    }
  }
  &__time {
    @extend .table-badge-box;
    background-color: $tableTimeBadge;
    &-mas-5 {
      @extend .table-badge-box;
      background-color: #0c64f3;
    }
    &-mas-10 {
      @extend .table-badge-box;
      background-color: #0caaf3;
    }
    &-mas-15 {
      @extend .table-badge-box;
      background-color: #0ce7f3;
    }
    &-mas-40 {
      @extend .table-badge-box;
      background-color: #0cf3ae;
    }
    &-mas-60 {
      @extend .table-badge-box;
      background-color: #0cf336;
    }
  }
  &__ticket{
    @extend .table-badge-box;
    background-color: $tableTicketBadge;
    &-problema-fac{
      @extend .table-badge-box;
      background-color: #F75800
    }
    &-no-ticket{
      @extend .table-badge-box;
      background-color: #eca883
    }
  }
  &__other {
    @extend .table-badge-box;
    background-color: $tableOtherBadge;
    &-other {
      @extend .table-badge-box;
      background-color: #0c1a0f;
    }
  }
  &__problem-ordering {
    @extend .table-badge-box;
    background-color: $tableProblemOredringBadge;
    &-appnosirve {
      @extend .table-badge-box;
      background-color: #2bf30c;
    }
    &-nosucursal {
      @extend .table-badge-box;
      background-color: #9ef30c;
    }
    &-metodospago {
      @extend .table-badge-box;
      background-color: #d4f30c;
    }
    &-pedidomaquina {
      @extend .table-badge-box;
      background-color: #f3d00c;
    }
    &-confirmacion {
      @extend .table-badge-box;
      background-color: #f36c0c;
    }
    &-modificar {
      @extend .table-badge-box;
      background-color: #f3270c;
    }
  }
  &__problem-picking-up-order {
    @extend .table-badge-box;
    background-color: $tableProblemPickingUpOrderBadge;
    &-noqr {
      @extend .table-badge-box;
      background-color: #ef3d3d;
    }
    &-incorrecta {
      @extend .table-badge-box;
      background-color: #ef3db4;
    }
    &-sirvemaquina {
      @extend .table-badge-box;
      background-color: #bf3def;
    }
    &-ordennolista {
      @extend .table-badge-box;
      background-color: #783def;
    }
    &-equivocada {
      @extend .table-badge-box;
      background-color: #3d6cef;
    }
  }
}

@import "../../../node_modules/bootstrap/scss/bootstrap";

@font-face {
  font-family: 'Emigre Bold';
  src: url('../../assets/fonts/emigre-boldotf.eot');
  src: url('../../assets/fonts/emigre-boldotf.eot') format('embedded-opentype'),
  url('../../assets/fonts/emigre-boldotf.ttf') format('truetype'),
  url('../../assets/fonts/emigre-boldotf.woff') format('woff'),
  url('../../assets/fonts/emigre-boldotf.svg') format('svg');
  font-weight: Bold;
  font-style: Bold; 
}

@font-face {
	font-family: 'Emigre';
  src: url('../../assets/fonts/emigre.eot');
  src: url('../../assets/fonts/emigre.eot') format('embedded-opentype'),
  url('../../assets/fonts/emigre.ttf') format('truetype'),
  url('../../assets/fonts/emigre.woff') format('woff'),
  url('../../assets/fonts/emigre.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
  font-family: 'icomoon';
  src:  url('../../assets/fonts/icomoon.eot?qshad7');
  src:  url('../../assets/fonts/icomoon.eot?qshad7#iefix') format('embedded-opentype'),
    url('../../assets/fonts/icomoon.ttf?qshad7') format('truetype'),
    url('../../assets/fonts/icomoon.woff?qshad7') format('woff'),
    url('../../assets/fonts/icomoon.svg?qshad7#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

body{
  background:#eeeff4; 
}

.emigre{
	font-family: 'Emigre';
}

.emigre-bold{
	font-family: 'Emigre Bold';
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-CERRARSESION:before {
  content: "\e916";
}
.icon-SUCURSALES:before {
  content: "\e917";
}
.icon-USUARIOS:before {
  content: "\e918";
}


.icon-gear:before {
  content: "\e914";
}
.icon-globe:before {
  content: "\e915";
}
.icon-search:before {
  content: "\e900";
}
.icon-logo:before {
  content: "\e901";
}
.icon-atc:before {
  content: "\e902";
}
.icon-cerrado:before {
  content: "\e903";
}
.icon-conversando:before {
  content: "\e904";
}
.icon-date:before {
  content: "\e905";
}
.icon-edit:before {
  content: "\e906";
}
.icon-shop:before {
  content: "\e907";
}
.icon-speed:before {
  content: "\e908";
}
.icon-tag:before {
  content: "\e909";
}
.icon-user_service:before {
  content: "\e90a";
}
.icon-sucursal:before {
  content: "\e90b";
}
.icon-mail:before {
  content: "\e90c";
}
.icon-cel:before {
  content: "\e90d";
}
.icon-otros:before {
  content: "\e90e";
}
.icon-producto:before {
  content: "\e90f";
}
.icon-save:before {
  content: "\e910";
}
.icon-servicio:before {
  content: "\e911";
}
.icon-quejascc:before {
  content: "\e912";
}
.icon-tiempo:before {
  content: "\e913";
}

.icon-ticket:before{
  content: "\e906";
}

$orangeLC: #ff7534;

.bg-lc{
  background: $orangeLC;
}

.txt-lc{
  color: $orangeLC;
}

.container{
    // max-width: 1272px;
    max-width: 1400px;
}

.badge-line{
  position: relative;
  font-size: 65%;
  vertical-align: middle;
  margin-left: 35px !important;
  &::before{
    content: '';
    display: block;
    height: 1px;
    width: 30px;
    background: #BCBFCF;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: -30px;
    padding-left: 5px; 
  }
}

.vertical-text{
  @media (min-width: 768px){
    writing-mode: vertical-lr;
    transform: rotate(180deg);
    text-orientation: sideways;
    text-align:right;
    text-orientation: sideways;
    margin: 0;
    width: 100%;
  }
}

.z-indez-99{
  z-index: 18;
}
