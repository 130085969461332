.overview {
  padding-top: 100px;
  padding-bottom: 64px;
}

.overview {
  .nav-item {
    border: 1px solid #dee2e6;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    cursor: pointer;
    .subActiveLink {
      color: #ff7534 !important;
    }
  }
  .activeTabs {
    background: #fff;
    color: #000;
  }
  .overviewSubTabs {
    border: 0;
  }
}

button[aria-label="Export"] span span {
  color: #ff7534 !important;
}

.tab-content {
  border: 1px solid #dee2e6;
  background: rgb(255, 255, 255);
  padding-top: 2em;
}

.table-cell-header {
  color: #fff;
}

.table-cell-border {
  border-bottom: 1px solid #ccc !important;
}

text.recharts-text.recharts-label {
  fill: #000;
  font-weight: 600;
}

.recharts-legend-item-text {
  color: unset !important;
}

.loading-data {
  align-items: center;
  background-color: rgba(255, 255, 255, 0.6);
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}

.MuiCircularProgress-colorPrimary {
  color: #ffc107 !important;
}

.ml-8 {
  margin-left: 3rem;
}

.mb-8 {
  margin-bottom: 3rem;
}

.cursor-down:hover{
  cursor: pointer ;
  text-decoration: underline;
}

.black-icon {
  color: #000;
}

.text_descarga {
  text-align: start;
  color: #ff7534 !important;
}

.text_descarga:hover{
  text-decoration: none;
}

@media (min-width: 1440px) {
  .max-width1440-center {
    max-width: 1440px !important;
    margin: 0 auto !important;
  }
}
