.legends-doughnuts{
    font-size: 10px;
}

.dougnut-charts-table__td-title {
    width: 90%;
}

.dougnut-charts-table__td-value {
    width: 10%;
}

.dougnut-charts-table__td-no-borders {
    padding: 0 !important;
}

.dougnut-charts-table__table {
    border: 1px solid #dee2e6;
    background: rgb(255,255,255);
    background: -moz-linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,117,52,0.75) 100%);
    background: -webkit-linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,117,52,0.75) 100%);
    background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,117,52,0.75) 100%);
}

.dougnut-charts-table__td-title,
.dougnut-charts-table__td-value {
    border-top: unset !important;
}