.componentUsers{
    .filters{
        margin-top: 0px !important; 
    }
    .card-header{
        background-color: #424242; 
    }
    .btn-secondary {
        background-color: transparent;
    }
    .dropdown-menu{
        width: 100%;
    }
    input{
        border: none;
        border-bottom: 1px solid #ced4da;
    }
    input::placeholder{
        color: #BCBFCF;
    }
    .add-user{
        width: 100%
    }
    .button-add{
        padding: 5px 40px
    }
    
    @media (max-width: 575.98px) { 
        .button-new-user{
            width: 100%;
        }
        .details-user{
            button{
                width: 75%;
            }
            .button-modif-user{
                margin-bottom: 10px;
            }
        }
     }
}

.modal{
    .modal-body{
        margin: 60px 0 20px;
        .footer-modal{
            margin: 20px 20px;
            font-size: 14px; 
        }
    }
}

.spinner {
    position: absolute !important;
    border-radius: 50%;
    left: 44% !important;
    top: 54% !important;
    z-index: 100;
}

.MuiIcon-root {
    color: #808080 !important;
}

.full_width {
    margin-top: 10px;
}