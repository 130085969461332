.filters{
    .close{
            line-height: 0.4;
            margin-left: 10px;
    }
    .line-izq{
        font-size: 45%;
        vertical-align: middle;
        display: inline-block;
    }
    .chip{
        max-width: 100%;
        position: relative;
        display: inline-block;
    }
    .show-filters{
        cursor: pointer;
    }
    .search{
        align-items: flex-end;
        .form-control {
            border: none;
            background-color: transparent;
            border-bottom: 1px solid #424242;
            border-radius: 0;
        }
        .form-group{
            position: relative;
            .icon-search{
                position: absolute;
                right: 0;
                top: 7px;
            }
            .chip-close{
                padding: 0px 4px 2px 4px;
                line-height: 1;
                font-weight: bold;
                margin-left: 5px;
                position: relative;
                cursor: pointer;
                display: none;
                font-size:21px;
            
            }
        }
    }

    .peri{
        padding: 0px 15px;
    }

    .mr-8{
        margin-right: 4.5rem !important;
    }

    .css-5duvv3-control{
        width: 270px;
        // height: 38px;
    }

    .css-14jk2my-container{
        width: 270px;
        // height: 38px;
    }

    .css-16yjc6b-control{
        width: 270px;
        // height: 38px;
       
    } 
    .css-26l3qy-menu {
        width: 260px;
    }
    .css-1hb7zxy-IndicatorsContainer{
        // height: 38px;
    }

    // .css-1wy0on6{
    //     height: 38px;
    // }

    // .select__indicators{
    //     height: 38px;
    // }
    

    .container-button{
        display: flex;
        justify-content: flex-end;
        width: 100%;
        margin-right: 1em;

        .button-filter{
            background-color: #FF7534;
            border-radius: 25px;
            font-weight: bold;
            border: none;
            color: white;
            
            padding: 10px 25px 10px 25px;
        }
    }
}

@media(max-width:1337px) {
    .filters{
        .css-5duvv3-control{
            width: 250px;
        }
        .css-16yjc6b-control{
            width: 250px;
        }
        .css-26l3qy-menu {
            width: 230px;
        } 
        .css-14jk2my-container{
            width: 250px;
        }
    
    
        .mr-8{
            margin-right: 3.5rem !important;
        } 
    }
    
}

@media(max-width:1190px) {
    .filters{
        .css-5duvv3-control{
            width: 250px;
        } 
        .css-16yjc6b-control{
            width: 250px;
        } 
        .css-26l3qy-menu {
            width: 230px;
        }
        .css-14jk2my-container{
            width: 250px;
        }

        .mr-8{
            margin-right: 7.5rem !important;
        }
    }
    
}

@media(max-width:850px) {
    .filters{
        .css-5duvv3-control{
            width: 245px;
        } 
        .css-16yjc6b-control{
            width: 245px;
        } 
        .css-14jk2my-container{
            width: 245px;
        }
    }
    
}

@media(max-width:767px) {
    .filters{
        .css-5duvv3-control{
            width: auto;
        } 
        .css-16yjc6b-control{
            width: auto;
        } 
        .css-26l3qy-menu {
            width: auto;
        }
        .css-14jk2my-container{
            width: auto;
        }

    }
    
}

@media screen and (min-width: 600px) {
    .filters{
        .chip-close{
            display: inline-block !important;
            width: 26px;
            text-align: center;
            padding-bottom: 2px;
        }
    }
}