.download-image-ss-btn {
    border: none;
    background: transparent;
    color: #ff7534;
}

.download-image-ss-btn-disabled {
    border: none;
    background: transparent;
    color: gray;
}