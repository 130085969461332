.collapseHeader {
    padding: 10px 10px;
    background-color: #bcbfcf;
    border-radius: 5px;
    margin-bottom: 10px;
    h4 {
        margin: 0;
        padding-left: 5px;
        svg {
            float: right;
        }
        svg.closed {
            transform: rotate(180deg);
        }
    }
}