.service-unanswered, .product-unanswered, .time-unanswered, .other-unanswered{
    width: 10px;
    height: 10px;
    background: #eaebf2;
    display: inline-block;
    margin-right: 5px;
}

.service-answered{
    width: 10px;
    height: 10px;
    background: #f9cb11;
    display: inline-block;
    margin-right: 5px;
}
.product-answered{
    width: 10px;
    height: 10px;
    background: #29bff0;
    display: inline-block;
    margin-right: 5px;
}
.time-answered{
    width: 10px;
    height: 10px;
    background: #99de14;
    display: inline-block;
    margin-right: 5px;
}
.other-answered{
    width: 10px;
    height: 10px;
    background: #ff7534;
    display: inline-block;
    margin-right: 5px;
}

.no-answered{
    width: 10px;
    height: 10px;
    background: #eaebf2;
    display: inline-block;
    margin-right: 5px;
}

.left-bar  {
    max-width: 230px; 
}

.app-not-working-answered{
    width: 10px;
    height: 10px;
    background: #1A535C;
    display: inline-block;
    margin-right: 5px;
}

.no-sucursal-answered{
    width: 10px;
    height: 10px;
    background: #4ECDC4;
    display: inline-block;
    margin-right: 5px;
}

.payment-methods-problems-answered{
    width: 10px;
    height: 10px;
    background: #FF6B6B;
    display: inline-block;
    margin-right: 5px;
}

.no-machine-order-answered{
    width: 10px;
    height: 10px;
    background: #FFE66D;
    display: inline-block;
    margin-right: 5px;
}

.order-confirmation-answered{
    width: 10px;
    height: 10px;
    background: #88D498;
    display: inline-block;
    margin-right: 5px;
}

.cancel-modify-order-answered{
    width: 10px;
    height: 10px;
    background: #1C1C1C;
    display: inline-block;
    margin-right: 5px;
}

.problem-ordering-box {
    width: 10px;
    height: 10px;
    background: #1A535C;
    display: inline-block;
    margin-right: 5px;
}

.problem-picking-up-order-box {
    width: 10px;
    height: 10px;
    background: #4ECDC4;
    display: inline-block;
    margin-right: 5px;
}
.problem-product-box {
    width: 10px;
    height: 10px;
    background: #FF6B6B;
    display: inline-block;
    margin-right: 5px;
}
.problem-service-box {
    width: 10px;
    height: 10px;
    background: #FFE66D;
    display: inline-block;
    margin-right: 5px;
}
.problem-other-box {
    width: 10px;
    height: 10px;
    background: #88D498;
    display: inline-block;
    margin-right: 5px;
}
